import React from 'react';
import { connect } from 'react-redux'
import Image from 'react-image'
import PropTypes from 'prop-types'

const UpperLineBranding = ({ currentTheme }) => {
    return (
        <div style={{ background: `${currentTheme.logo_bar_background}` }}>
            <div className="col-md-3 col-sm-6 px-0">
                <div style={{ padding: '5% 10%' }}>
                    <Image
                        width='100%'
                        src={currentTheme.logo}
                    />
                </div>
            </div>
        </div>);
}

const mapStateToProps = (state) => {
    return {
        currentTheme: state.theme,
    }
}

UpperLineBranding.propTypes = {
    currentTheme: PropTypes.object
}

const ConnectedUpperLineBranding = connect(mapStateToProps)(UpperLineBranding)

export default ConnectedUpperLineBranding;
