import React, { Component } from "react";
import { connect } from 'react-redux';
import { PropTypes } from "prop-types";
import { MDBCol } from "mdbreact";
import { I18n } from 'react-i18nify';
import { filterPaymentMethod } from "../filters/filterPaymentMethod";
import * as PAYMENTMETHODSCONST from './../constants/paymentMethods';
import ConnectedPaymentezDescriptionPayment from "./PaymentezApi/PaymentezDescriptionPayment";
import { PSE_LOGO } from "../constants/resources";
import ConnectedPSEPaymentComponent from "./OtherPaymentApi/PSEPaymentComponent";
import ConnectedCashPaymentComponent from "./OtherPaymentApi/CashPaymentComponent";
import ConnectedColsubsidioPaymentComponent from "./OtherPaymentApi/ColsubsidioPaymentComponent";
import ConnectedBradescoPaymentComponent from "./OtherPaymentApi/BradescoPaymentComponent";
import ConnectedDaviplataPaymentComponent from './OtherPaymentApi/DaviplataPaymentComponent';
import ConnectedQrPaymentComponent from "./OtherPaymentApi/QrPaymentComponent";
import SpinnerOverlay from "./SpinnerOverlay";
import ConnectedBancolombiaPaymentComponent from "./OtherPaymentApi/BancolombiaPaymentComponent";
import ConnectedBanktransferPaymentComponent from "./OtherPaymentApi/BanktrasferPaymentComponent";
import ConnectedRappiPaymentComponent from "./OtherPaymentApi/RappiPaymentComponent";
import ConnectedPixPaymentComponent from "./OtherPaymentApi/PixPaymentComponent";


export class PaymentMethodCollapse extends Component {
  constructor() {
    super();
    this.state = {
      showOverlay: false
    };
    this.handleShowOverlay = this.handleShowOverlay.bind(this);
  }

  handleShowOverlay = () => {
    this.setState({ showOverlay: true });
  }
    createPaymentMethod(paymentMethodName, payment) {
        paymentMethodName = paymentMethodName.toLowerCase();
        const { orderId, currentTheme, termsAccepted } = this.props;
        let data = undefined;
        let order = { orderId, payment: { ...payment }, orderData: this.props.orderData, currentTheme, disabled: !termsAccepted };

        switch (true) {
            case new RegExp(PAYMENTMETHODSCONST.CREDIT, "i").test(paymentMethodName):
            case new RegExp(PAYMENTMETHODSCONST.DEBITCARD, "i").test(paymentMethodName):
                data = <ConnectedPaymentezDescriptionPayment {...order} />
                break
            case new RegExp(PAYMENTMETHODSCONST.DEBIT, "i").test(paymentMethodName):
              if (payment.carrier === PAYMENTMETHODSCONST.SAFETYPAY) {
                order = {
                  ...order,
                  typeOrder: "safetypay",
                }
                data = <ConnectedBanktransferPaymentComponent {...order}/>
              } else {
                order = {
                  ...order,
                  typeOrder: "pse",
                  logo: PSE_LOGO
                }
                data = <ConnectedPSEPaymentComponent {...order} {...this.props.orderData} />
              }
              break;
            case PAYMENTMETHODSCONST.CASH === paymentMethodName && payment.carrier === PAYMENTMETHODSCONST.BRADESCO:
              order = {
                ...order,
                typeOrder: PAYMENTMETHODSCONST.BRADESCO.toLowerCase(),
              }
              data = <ConnectedBradescoPaymentComponent {...order} />
              break;
            case new RegExp(PAYMENTMETHODSCONST.CASH, "i").test(paymentMethodName):
                order = {
                    ...order,
                    typeOrder: "cash",
                }
                data = <ConnectedCashPaymentComponent {...order} />
                break;
            case paymentMethodName === PAYMENTMETHODSCONST.COLSUBSIDIO:
                order = {
                    ...order,
                    typeOrder: "colsubsidio",
                }
                data = <ConnectedColsubsidioPaymentComponent {...order} />
                break;
            case new RegExp(PAYMENTMETHODSCONST.EWALLET, "i").test(paymentMethodName):
                order = {
                    ...order,
                    typeOrder: "daviplata",
                }
                data = <ConnectedDaviplataPaymentComponent {...order} {...this.props.orderData} />
                break;
            case paymentMethodName === PAYMENTMETHODSCONST.QR:
              order = {
                ...order,
                typeOrder: "qr",
              }
              data = <ConnectedQrPaymentComponent {...order} spinner={this.handleShowOverlay}/>
              break;
            case paymentMethodName === PAYMENTMETHODSCONST.PIX:
              order = {
                ...order,
                typeOrder: "pix",
              }
              data = <ConnectedPixPaymentComponent {...order} spinner={this.handleShowOverlay}/>
            break;
            case paymentMethodName === PAYMENTMETHODSCONST.BANCOLOMBIA:
              order = {
                ...order,
                typeOrder: "bancolombia",
              }
              data = <ConnectedBancolombiaPaymentComponent {...order}/>
              break;
            case paymentMethodName === PAYMENTMETHODSCONST.RAPPI:
              order = {
                  ...order,
                  typeOrder: "rappi",
                }
                data = <ConnectedRappiPaymentComponent {...order}/>
                break;
        }
        return data;
    }

    paymentMethods = () => {
        const { paymentMethods } = this.props;
        return (
            paymentMethods.map((payment) => {
                const paymentName = payment.name.toLowerCase()
                return (
                    <MDBCol className="px-1" key={`collapse-key-${paymentName}`} md="12">
                        {this.createPaymentMethod(paymentName, payment)}
                    </MDBCol>
                )
            })
        )
    }

    render() {
        const { paymentMethods, currentTheme, statusOrder } = this.props;
        return (
            <>
              {
                    paymentMethods.length ?
                        <h4 style={{ color: currentTheme.primary_color, fontFamily: currentTheme.font_primary }} className="pt-4 text-center">
                            <strong>{I18n.t('pick-payment-method')}</strong>
                        </h4> : null
              }
              <SpinnerOverlay active={this.state.showOverlay} marginTop="0px">
                <div className="row d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
                    {(() => {
                        if(statusOrder && statusOrder.order && statusOrder.order.status === "Payed") {
                          return (
                            <p style={{ color: currentTheme.primary_color, fontSize: '23px' }} className="text-center">{I18n.t('pick-payed-link')}</p>
                          )
                        }
                        if(statusOrder && statusOrder.order && statusOrder.order.status !== "Expired") {
                            return this.paymentMethods()
                        }
                        if (
                          statusOrder &&
                          statusOrder.order &&
                          statusOrder.order.status === "Expired"
                        ) {
                          return (
                            <p
                              style={{
                                color: currentTheme.primary_color,
                                fontSize: "23px",
                              }}
                              className="text-center"
                            >
                              {I18n.t("pick-expired-link")}
                            </p>
                          );
                        }
                        return (
                          <p
                            style={{ color: currentTheme.primary_color, fontSize: "23px" }}
                            className="text-center"
                          >
                            {I18n.t("pick-loading")}...
                          </p>
                        );
                      })()}
                </div>
              </SpinnerOverlay>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    const payment = state.detalles && state.detalles.data ? state.detalles.data.payment : {};
    return ({
        currentTheme: state.theme,
        paymentMethods: filterPaymentMethod(payment),
        statusOrder: state.detalles.data
    })
};

PaymentMethodCollapse.propTypes = {
    orderId: PropTypes.string,
    currentTheme: PropTypes.object,
    paymentMethods: PropTypes.arrayOf(PropTypes.object),
    termsAccepted: PropTypes.bool,
};

const ConectedPaymentMethodCollapse = connect(
    mapStateToProps
)(PaymentMethodCollapse)

export default ConectedPaymentMethodCollapse;
