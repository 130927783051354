import { BASE_SERVER, DEFAULT_THEME } from "../constants/config";

const PaymentezTheme = {
  name: 'Paymentez',
  logo: "https://cdn.paymentez.com/img/paymentez_nuvei.png",
  primary_color: "#00BF84",
  primary_color_contrast: "rgba(255,255,255,0.85)",
  primary_color_text_over: "white",
  secondary_color: "#545454",
  side_bar_color: "#F8F8F8",
  background_image: null,
  site: "https://paymentez.com/",
  favicon: "/favicon_ptz.svg",
  domain: "paymentez.com",
  side_bar_font: "#000",
  primary_color_font: "#000",
};

const GlobalPayTheme = {
  name: 'GlobalPay Redeban',
  logo: BASE_SERVER + "/img/logo_global.png",
  primary_color: "#F49D31",
  primary_color_contrast: "rgba(255,255,255,0.85)",
  primary_color_text_over: "white",
  secondary_color: "#545454",
  side_bar_color: "#F8F8F8",
  background_image: null,
  site: "https://www.rbmcolombia.com",
  favicon: "/favicon_click.ico",
  domain: "globalpay.com.co",
  side_bar_font: "#000",
  primary_color_font: "#000"
};

const PagosRedebanTheme = {
  name: 'Pagos Redeban',
  logo: 'https://cdn.paymentez.com/img/Logo_Pagos-Redeban.png',
  primary_color: "#FF6D01",
  primary_color_contrast: "rgba(255,255,255,0.85)",
  primary_color_text_over: "white",
  secondary_color: "#545454",
  side_bar_color: "#F8F8F8",
  background_image: null,
  site: "https://www.rbmcolombia.com",
  favicon: "/favicon_click.ico",
  domain: "pagosredeban.com",
  services_domain: "globalpay.com.co",
  side_bar_font: "#000",
  primary_color_font: "#000",
};

const ShieldGateTheme = {
  name: 'ShieldGate',
  logo: 'https://cdn.shieldgate.mx/img/Logo_ShieldGate.png',
  primary_color: "#97D700",
  primary_color_contrast: "rgba(255,255,255,0.85)",
  primary_color_text_over: "white",
  secondary_color: "#545454",
  side_bar_color: "#F8F8F8",
  background_image: null,
  site: "https://www.shieldgate.mx",
  favicon: "/favicon_shieldgate.svg",
  domain: "shieldgate.mx",
  side_bar_font: "#000",
  primary_color_font: "#000"
};

const GpvicommTheme = {
  name: 'gpvicomm',
  logo: 'https://cdn.gpvicomm.com/Logo_hsbc.png',
  primary_color: "#003C71",
  primary_color_contrast: "rgba(255,255,255,0.85)",
  primary_color_text_over: "#fff",
  secondary_color: "#626469",
  side_bar_color: "#F8F8F8",
  background_image: null,
  site: "https://www.hsbc.com.mx/",
  favicon: "https://cdn.gpvicomm.com/logo_hsbc.svg",
  domain: "gpvicomm.com",
  side_bar_font: "#000",
  primary_color_font: "#000",
};

const RedebanTheme = {
  name: 'Redeban',
  logo: 'https://cdn.paymentez.com/img/Logo_Redeban_Color.png',
  primary_color: "#0033A1",
  primary_color_contrast: "rgba(255,255,255,0.85)",
  primary_color_text_over: "white",
  secondary_color: "#545454",
  side_bar_color: "#F8F8F8",
  background_image: null,
  site: "https://www.rbmcolombia.com",
  favicon: "/favicon_click.ico",
  domain: "redeban.com",
  services_domain: "globalpay.com.co",
  side_bar_font: "#000",
  primary_color_font: "#000",
  button_color_hover: "white",
  button_opacity_hover:"0.8"
};

const MyCheckoutTheme = {
  name: 'MyCheckout',
  logo: 'https://cdn.mycheckout.nizza.com/Logo_mycheckout_horizontal.png',
  primary_color: "#0097C8",
  primary_color_contrast: "rgba(255,255,255,0.85)",
  primary_color_text_over: "white",
  secondary_color: "#000000",
  side_bar_color: "#F8F8F8",
  background_image: null,  
  favicon: "https://cdn.mycheckout.nizza.com/favicon_MyCheckout.svg",
  domain: "mycheckout.nizza.com",
  side_bar_font: "#000",
  primary_color_font: "#000"
};


const Themes = {
  paymentez: PaymentezTheme,
  link: PaymentezTheme,
  globalpay: GlobalPayTheme,
  localhost: PaymentezTheme,
  netlify: GlobalPayTheme,
  pagosredeban: PagosRedebanTheme,
  shieldgate: ShieldGateTheme,
  gpvicomm: GpvicommTheme,
  redeban: RedebanTheme,
  mycheckout: MyCheckoutTheme,

  getThemeByLocation: () => {
    const hostname = window.location.hostname;
    let domain = hostname === 'localhost' ? hostname : hostname.split('.')[1];
    if (domain === 'netlify' && DEFAULT_THEME) {
      domain = DEFAULT_THEME
    }
    return Themes[domain]
  }
};

export default Themes;
