import React from 'react';
import PropTypes from 'prop-types';
import { getFormatedNumberToCurrency } from '../../utils/CurrencyFormat';

export const getAvalibleColor = ( theme={} )=>{
    const {side_bar_middle_colorFont = null, primary_color = null} = theme;
    switch (true) {
        case side_bar_middle_colorFont != null:
           return  side_bar_middle_colorFont;
        case primary_color != null:
            return  primary_color;
        default:
            return  "inherit";
    }
}

const PaymentWillBe = ({ currency, amount, theme, ...props }) => {
    return (
        <span 
            {...props} 
            style={{ 
                color: getAvalibleColor(theme)
            }}>
            {getFormatedNumberToCurrency(amount, currency)}
            <span className="o-amount--currency">{currency}</span>
        </span>
    );
}

PaymentWillBe.propTypes = {
    currency: PropTypes.string,
    amount: PropTypes.any
}

export default PaymentWillBe;
