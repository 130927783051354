import { CURRENCIES } from "../constants/paymentMethods";

export const getCurrencyObj = (curr) => {
    const data = CURRENCIES.filter((currency) => {
        
        return currency.currency[0] === curr;
    })
    return data[0] || CURRENCIES[0];
}


export const getFormatedNumberToCurrency = (amount, currency) => {
    const currencyExceptions = [
        "COP", "MXN", "ARS", "BRL", "CLP", "CRC", "UYU",
        "PEN", "DOP", "HNL", "BZD", "GTQ", "NIO", "PAB",
        "CUP", "PYG", "VES", "BOB", "MAD", "PLN", "SAR",
        "ZAR", "SEK", "CHF", "TRY", "VND"
    ]

    const locale = getCurrencyObj(currency).style
    const symbol = getCurrencyObj(currency).symbol
    const separator = getCurrencyObj(currency).separator
    const decimalSeparator = getCurrencyObj(currency).decimalSeparator

    const fixFormat = (number, separator=",", decimalSeparator=".") => {
        const exp = /(\d)(?=(\d{3})+(?!\d))/g;
        const rep = `$1${separator}`;
        let arr = number.toString().split('.');
        arr[0] = arr[0].replace(exp,rep);
        arr[1] = !arr[1] ? '00': arr[1];
        return arr.join(decimalSeparator);
    }

    if(currencyExceptions.includes(currency)) {
        return `${symbol} ${fixFormat(amount, separator, decimalSeparator)}`;
    } else {
        return currency ? 
            new Intl.NumberFormat(
                locale,
                { 
                    style: 'currency',
                    currency: currency,
                    minimumFractionDigits: 2 
                }).format(amount) : null
    } 
}
