import React, { Component } from 'react';
import { connect } from 'react-redux';
import propTypes from "prop-types";
import { I18n } from 'react-i18nify';
import { asyncfetchOtherPaymentStatus } from '../../actions/serverAnswerActions';
import BttnPayment from '../PageBuilder/BttnPayment';
import {multiUpdateSingleMulti} from "../../utils/convertMultiLevelIntoSingle";

export class QrPaymentComponent extends Component {

  sendData = () => {
    this.props.spinner()
    const { url } = this.props.payment;
    const { responseStatus, orderDetails, orderData } = this.props;
    const newData = multiUpdateSingleMulti(orderData, orderDetails.data);
    const newOrderData = {
      ...orderDetails, data: { ...newData }
    }
    responseStatus(url, newOrderData)
  }

  render() {
    const {typeOrder = "", currentTheme, disabled } = this.props;
    const { sendData } = this;
    return (<>
      <div className="full-centering">
        <BttnPayment 
          disabled={disabled}
          currentTheme={currentTheme}
          internationalization={I18n.t(`payment-action-button-${typeOrder}`)}
          showForm={() => {sendData()}} // onClick en el componente de botón
        />
      </div>
    </>);
  }
}

QrPaymentComponent.propTypes = {
  orderId: propTypes.string,
  currentTheme: propTypes.object,
  typeOrder: propTypes.string,
  color: propTypes.string,
  logo: propTypes.any,
  payment: propTypes.object,
  responseStatus: propTypes.any,
  orderDetails: propTypes.object,
  orderData: propTypes.object,
  spinner: propTypes.func,
  disabled: propTypes.bool,
}

const mapStateToProps = (state) => ({ //state, ownProps
  currentTheme: state.theme,
  orderDetails: state.detalles
});

const mapDispatchToProps = (dispatch) => ({
  responseStatus: (url, data) => dispatch(asyncfetchOtherPaymentStatus(url, data)),
})

const ConnectedQrPaymentComponent = connect(
  mapStateToProps, mapDispatchToProps
)(QrPaymentComponent)

export default ConnectedQrPaymentComponent;
