export const DEBITCARD ='debit';
export const RAPPI ='rappi';
export const SAFETYPAY ='safetypay';
export const BANCOLOMBIA ='bancolombia';
export const QR = 'qr';
export const PIX = 'pix';
export const CASH = 'cash';
export const CREDIT = 'cArd';
export const DEBIT = 'banktrANsfer';
export const COLSUBSIDIO = "colsubsidio";
export const BRADESCO = "Bradesco";
export const EWALLET = "Ewallet";
export const CURRENCIES = [
    { currency: ["USD"], symbol: "$", style: "en-US", separator: "," },
    { currency: ["EUR"], symbol: "€", style: "es-ES", separator: "." },
    { currency: ["YEN"], symbol: "¥", style: "ja-JP", separator: "." },
    { currency: ["COP"], symbol: "$", style: "es-CO", separator: ".", decimalSeparator: ',' }, // Colombia
    { currency: ["MXN"], symbol: "$", style: "es-MX", separator: ",", decimalSeparator: '.' }, // México
    { currency: ["BRL"], symbol: "$", style: "pt-BR", separator: ".", decimalSeparator: ',' }, // Brasil
    { currency: ["ARS"], symbol: "$", style: "es-AR", separator: ".", decimalSeparator: ','  }, //Argentina
    { currency: ["CLP"], symbol: "$", style: "es-CL", separator: ".", decimalSeparator: ','  }, //Chile
    { currency: ["CRC"], symbol: "₡", style: "es-CR", separator: "", decimalSeparator: ','  }, //Costa Rica
    { currency: ["UYU"], symbol: "$", style: "es-UY", separator: ".", decimalSeparator: ','  }, // Uruguay
    { currency: ["PEN"], symbol: "S/", style: "es-PE", separator: ",", decimalSeparator: "." }, // Perú
    { currency: ["DOP"], symbol: "RD$", style: "es-DO", separator: ",", decimalSeparator: "." }, // República Dominicana
    { currency: ["HNL"], symbol: "L", style: "es-HN", separator: ",", decimalSeparator: "." }, // Honduras
    { currency: ["BZD"], symbol: "$", style: "es-BZ", separator: ",", decimalSeparator: "." }, // Bélice
    { currency: ["GTQ"], symbol: "Q", style: "es-GT", separator: ",", decimalSeparator: '.'  }, //Guatemala
    { currency: ["NIO"], symbol: "C$", style: "es-NI", separator: ",", decimalSeparator: '.'  }, // Nicaragua
    { currency: ["PAB"], symbol: "B/.", style: "es-PA", separator: ",", decimalSeparator: '.' }, // Pánama
    { currency: ["CUP"], symbol: "$", style: "es-CU", separator: ",", decimalSeparator: '.' }, // Cuba
    { currency: ["PYG"], symbol: "Gs.", style: "es-PY", separator: ".", decimalSeparator: ',' }, // Paraguay
    { currency: ["SVC"], symbol: "C", style: "es-SV", separator: ",", decimalSeparator: '.' }, // El Salvador
    { currency: ["VES"], symbol: "Bs.S", style: "es-VE", separator: ".", decimalSeparator: ',' }, // Venezuela
    { currency: ["BOB"], symbol: "Bs", style: "es-BO", separator: ".", decimalSeparator: ',' }, // Bolivia
    { currency: ["AUD"], symbol: "$", style: "en-AU", separator: "," }, //Australia
    { currency: ["CAD"], symbol: "$", style: "en-CA", separator: "," }, // Cánada
    { currency: ["SGD"], symbol: "$", style: "en-SG", separator: "," }, //Singapur
    { currency: ["CNY"], symbol: "¥", style: "zh-CN", separator: "," }, //China
    { currency: ["INR"], symbol: "₹", style: "hi-IN", separator: "," }, // India
    { currency: ["GBP"], symbol: "£", style: "en-GB", separator: "," }, // Reino Unido
    { currency: ["MAD"], symbol: "د.م.‏", style: "ar-MA", separator: ".", decimalSeparator: ',' }, //Marruecos
    { currency: ["PHP"], symbol: "₱", style: "en-PH", separator: "," }, // Filipinas
    { currency: ["PLN"], symbol: "zł", style: "pl-PL", separator: ".", decimalSeparator: ',' }, //Polonia
    { currency: ["RUB"], symbol: "₽", style: "ce-RU", separator: "," }, // Rusia
    { currency: ["SAR"], symbol: "ر.س.‏", style: "ar-SA", separator: ".", decimalSeparator: ',' }, // Arabia Saudita
    { currency: ["ZAR"], symbol: "R", style: "en-ZA", separator: ".", decimalSeparator: ',' }, // Sudáfrica
    { currency: ["SEK"], symbol: "kr", style: "en-SE", separator: "", decimalSeparator: ','  }, // Suecia
    { currency: ["CHF"], symbol: "CHF", style: "en-CH", separator: "'", decimalSeparator: '.'  }, // Suiza
    { currency: ["TWD"], symbol: "$", style: "zh-Hant-TW", separator: "," }, // Taiwan
    { currency: ["THB"], symbol: "฿", style: "th-TH", separator: "," }, // Tailandia
    { currency: ["TRY"], symbol: "₺", style: "tr-TR", separator: ".", decimalSeparator: ','  }, // Turquía
    { currency: ["VND"], symbol: "₫", style: "vi-VN", separator: ".", decimalSeparator: ''  } // Vietnam
];
