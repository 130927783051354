import React from 'react';
import { connect } from 'react-redux';
import propTypes from "prop-types";
import ConnectedPaymentCreditCardPaymentez from "./PaymentCreditCardPaymentez";
import ConnectedPaymentDebitCardPaymentez from "./PaymentDebitCardPaymentez";

export const PaymentezDescriptionPayment = ({ currentTheme, orderData, ...props }) => {
    const { payment } = props;
    if (payment.carrier === "ccapi") { 
        const typeOrder = "paymentez";
        return (
            <div className="full-centering">
                <ConnectedPaymentCreditCardPaymentez
                    {...props}
                    orderData={orderData}
                    currentTheme={currentTheme}
                    typeOrder={typeOrder}
                />
            </div >
    )
    }
    else {
        const typeOrder = "paymentez-debit";
        return (
            <div className="full-centering">
                <ConnectedPaymentDebitCardPaymentez
                    {...props}
                    orderData={orderData}
                    currentTheme={currentTheme}
                    typeOrder={typeOrder}
                />
            </div >
        )
    }
}

PaymentezDescriptionPayment.propTypes = {
    orderId: propTypes.string,
    currentTheme: propTypes.object
}

const mapStateToProps = (state) => ({ //state, ownProps
    currentTheme: state.theme,
});

const ConnectedPaymentezDescriptionPayment = connect(
    mapStateToProps)(PaymentezDescriptionPayment)

export default ConnectedPaymentezDescriptionPayment;
